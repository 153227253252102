<template>
  <q-card class="my-card text-left q-ma-md q-pb-sm">
    <q-list>
      <q-item>
        <q-item-section>
          <q-item-label class="text-weight-bold">{{
            $t("Profile.personal-address.my-address")
          }}</q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-icon
            class="cursor-pointer icon-white"
            color="primary"
            name="edit"
            @click="dialog = true"
          />
        </q-item-section>
      </q-item>
      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>{{
            $t("Profile.personal-address.address")
          }}</q-item-label>

          <q-item-label v-if="user.shipping_address != null"
            >{{ user.shipping_address }}
          </q-item-label>
          <q-item-label v-else>{{
            $t("Profile.personal-address.please-introduce-address")
          }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-card>

  <EditPersonalAddress v-model="dialog" />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapState } from "vuex";

const EditPersonalAddress = defineAsyncComponent(() =>
  import("../editprofile/EditPersonalAddress.vue")
);

export default {
  components: {
    EditPersonalAddress,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.q-card {
  border-radius: 25px 25px 25px 0px;
}
</style>
